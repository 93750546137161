<template>
  <section v-if="allLogin != undefined">
    <h3>Job Information</h3>
    <div class="boxinside">
      <v-form
        ref="companyBasicForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <div class="fullwidth">
          <label class="mr-btm">Job Position</label>
          <v-text-field
            v-model="jobPosition"
            outlined
            label="Job Position"
            class="mr-btm-minus"
            :rules="jobPositionRule"
          ></v-text-field>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">Subscription Type</label>
          <v-select
            v-model="subscriptionType"
            :items="subscriptionTypes"
            label="Subscription Type"
            :rules="subscriptionRule"
            outlined
          ></v-select>
        </div>

        <div class="fullwidth">
          <label class="mr-btm">No. of Vacancy</label>
          <v-text-field
            v-model="numberOfVacancy"
            outlined
            label="No. of Vacancy"
            class="mr-btm-minus"
            :rules="noofvacancyRule"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Job Level</label>
          <v-select
            v-model="jobLevel"
            :items="jobLevels"
            label="Job Level"
            :rules="jobLevelRule"
            outlined
          ></v-select>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Job Category</label>
          <v-select
            v-if="allCategory.results"
            v-model="category"
            :items="allCategory.results.list"
            item-text="title"
            item-value="categoryHandle"
            label="Job Category"
            @change="selectCat(category)"
            :rules="jobCategoryRule"
            outlined
          ></v-select>
        </div>
        <div class="fullwidth" v-if="subcategoriess != null">
          <label class="mr-btm">Job Subcategory</label>
          <v-select
            v-model="subcategories"
            :items="subcategoriess"
            item-text="title"
            item-value="title"
            label="Job Subcategory"
            outlined
            multiple
            :rules="jobSubCategoryRules"
            chips
          ></v-select>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Available For</label>
          <v-select
            v-model="availableFor"
            :items="jobTypes"
            label="Available For"
            outlined
            :rules="availableForRules"
          ></v-select>
        </div>
        <div class="fullwidth">
          <v-checkbox
            v-model="isSalaryNegotiable"
            label="Is Salary Negotiable"
          ></v-checkbox>
        </div>
        <div class="fullwidth salarybox" v-if="!isSalaryNegotiable">
          <v-select
            v-model="currency"
            :items="currencies"
            label="NRs"
            outlined
          ></v-select>
          <v-text-field
            v-model="amountMinimum"
            label="Minimum"
            outlined
          ></v-text-field>
          <v-select
            v-model="timeframe"
            label="Payment As"
            :items="salaryTimes"
            outlined
          ></v-select>
        </div>
        <div class="fullwidth salarybox" v-if="!isSalaryNegotiable">
          <v-select
            v-model="currency"
            :items="currencies"
            label="NRs"
            outlined
          ></v-select>
          <v-text-field
            v-model="amountMaximum"
            label="Maximum"
            outlined
          ></v-text-field>
          <v-select
            v-model="timeframe"
            label="Payment As"
            :items="salaryTimes"
            outlined
          ></v-select>
        </div>

        <div
          class="fullwidth"
          v-if="
            (allLogin.user.role == 'admin' ||
              allLogin.user.role == 'employee') &&
            $route.params.slug != undefined
          "
        >
          <label class="mr-btm">Job Start Date</label>
          <v-dialog
            ref="startDate"
            v-model="modal1"
            :return-value.sync="jobStartDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="jobStartDate"
                label="Picker in Start Date"
                readonly
                v-bind="attrs"
                outlined
                v-on="on"
                :rules="jobStartDateRule"
              ></v-text-field>
            </template>
            <v-date-picker v-model="jobStartDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal1 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startDate.save(jobStartDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="fullwidth" v-if="$route.params.slug == undefined">
          <label class="mr-btm">Job Start Date</label>
          <v-dialog
            ref="startDate"
            v-model="modal1"
            :return-value.sync="jobStartDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="jobStartDate"
                label="Picker in Start Date"
                readonly
                v-bind="attrs"
                outlined
                v-on="on"
                :rules="jobStartDateRule"
              ></v-text-field>
            </template>
            <v-date-picker v-model="jobStartDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal1 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startDate.save(jobStartDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div
          class="fullwidth"
          v-if="
            (allLogin.user.role == 'admin' ||
              allLogin.user.role == 'employee') &&
            $route.params.slug != undefined
          "
        >
          <label class="mr-btm">Job End Date</label>
          <v-dialog
            ref="endDate"
            v-model="modal"
            :return-value.sync="jobEndDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="jobEndDate"
                label="Picker in End Date"
                readonly
                v-bind="attrs"
                :rules="jobEndDateRule"
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="jobEndDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endDate.save(jobEndDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="fullwidth" v-if="$route.params.slug == undefined">
          <label class="mr-btm">Job End Date</label>
          <v-dialog
            ref="endDate"
            v-model="modal"
            :return-value.sync="jobEndDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="jobEndDate"
                label="Picker in End Date"
                readonly
                v-bind="attrs"
                outlined
                v-on="on"
                :rules="jobEndDateRule"
              ></v-text-field>
            </template>
            <v-date-picker v-model="jobEndDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endDate.save(jobEndDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <div
          class="fullwidth"
          v-for="(jobLocation, index) in jobLocations"
          :key="index"
        >
          <label class="mr-btm">Job Location</label>
          <div class="removesection">
            <v-text-field
              v-model="jobLocation.location"
              outlined
              label="Job Location"
              class="mr-btm-minus"
            ></v-text-field>
            <div
              class="iconbox removebutton"
              @click="removeJobLocation(index)"
              v-if="index > 0"
            >
              <v-icon> mdi-minus-circle-outline </v-icon> Remove
            </div>
          </div>
        </div>

        <v-btn class="addmore spacebottom" @click="addJobLocation"
          ><v-icon> mdi-plus-circle-outline </v-icon> Add Location</v-btn
        >
        <div>
          <v-btn
            class="addmore"
            @click="saveJobInformation"
            v-if="!allloadingAd"
            >Next</v-btn
          >
          <v-btn class="addmore" v-if="allloadingAd"
            ><v-progress-circular indeterminate></v-progress-circular
          ></v-btn>
        </div>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "JobInformation",
  data: () => ({
    valid: true,
    msg: "",
    jobLocations: [{ location: "" }],
    subscriptionTypes: [
      // "Free Job",
      "Premium Job",
      "Standard Job",
      "Featured Job",
    ],
    subscriptionTypesc: [
      // "Free Job",

      "Standard Job",
    ],
    jobTypes: ["Daily", "Hourly", "Full Time", "Yearly"],
    jobLevels: ["Entry Level", "Mid Level", "Senior Level", "Top Level"],
    currencies: ["Nrs", "$", "Inr"],
    availableFors: [
      "Full Time",
      "Part Time",
      "Temporary",
      "Contract",
      "Freelance",
      "Traineership",
      "Internship",
      "Volunteer",
    ],
    salaryTimes: ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"],
    isSalaryNegotiable: false,
    jobStartDate: "",
    jobEndDate: "",
    modal: false,
    modal1: false,
    timeframe: "",
    amountMaximum: null,
    amountMinimum: null,
    currency: "",
    availableFor: "",
    subcategories: [],
    category: "",
    jobLevel: "",
    numberOfVacancy: "",
    subscriptionType: "",
    subcategoriess: null,
    jobPosition: "",
    id: null,
    jobStartDateRule: [(v) => !!v || "Job Start Date is required"],
    jobEndDateRule: [(v) => !!v || "Job End Date is required"],
    jobPositionRule: [(v) => !!v || "Job Position is required"],
    subscriptionRule: [(v) => !!v || "Subscription is required"],
    noofvacancyRule: [(v) => !!v || "Job Position is required"],
    jobLevelRule: [(v) => !!v || "Job Position is required"],
    jobCategoryRule: [(v) => !!v || "Job Categroy is required"],
    jobSubCategoryRules: [(v) => !!v || "Job SubCategory is required"],
    availableForRules: [(v) => !!v || "Job Position is required"],
  }),
  methods: {
    ...mapActions([
      "fetchCategory",
      "fetchSubCategory",
      "createJobInformation",
      "singleJobInformation",
      "singleJobInformationAdmin",
    ]),
    async saveJobInformation() {
      if (this.$refs.companyBasicForm.validate()) {
        let jobdata = {
          jobPosition: this.jobPosition,
          subscriptionType:
            this.subscriptionType == ""
              ? "Standard Job"
              : this.subscriptionType,
          numberOfVacancy: this.numberOfVacancy,
          jobLevel: this.jobLevel,
          category: this.category,
          subcategories: this.subcategories,
          availableFor: this.availableFor,
          isSalaryNegotiable: this.isSalaryNegotiable,
          minimumSalary: {
            currency: this.currency,
            salaryRange: this.amountMinimum,
            salary: this.timeframe,
          },
          maximumSalary: {
            currency: this.currency,
            salaryRange: this.amountMaximum,
            salary: this.timeframe,
          },
          jobStartDate: this.jobStartDate,
          jobEndDate: this.jobEndDate,
          jobLocations: this.jobLocations,
          userHandle: this.$route.params.company,
        };
        if (this.id != null) {
          jobdata.id = this.id;
        }
        await this.createJobInformation(jobdata);

        if (this.allJobInformation.status == 201) {
          if (!this.allloadingAd) {
            this.$emit("success", "job_sepcification");
          }
        } else {
          alert("Company profile is not complete please complete it");
        }
      }
    },
    addJobLocation() {
      this.jobLocations.push({ location: "" });
    },
    removeJobLocation(index) {
      this.jobLocations.splice(index, 1);
    },
    selectCat(cat) {
      this.subcategoriess = this.allSubcategory.results.list.filter((ele) => {
        return ele.categoryHandle == cat;
      });
    },
  },
  computed: {
    ...mapGetters([
      "allCategory",
      "allSubcategory",
      "allSingleJobInformation",
      "allLogin",
      "allloadingAd",
      "allJobInformation",
    ]),
  },
  async created() {
    this.fetchCategory();
    this.fetchSubCategory();
    if (this.$route.params.slug != undefined) {
      await this.singleJobInformation(this.$route.params.slug);
      this.jobPosition = this.allSingleJobInformation.results.jobPosition;
      this.subscriptionType =
        this.allSingleJobInformation.results.subscriptionType;
      this.numberOfVacancy =
        this.allSingleJobInformation.results.numberOfVacancy;
      this.jobLevel = this.allSingleJobInformation.results.jobLevel;
      this.category = this.allSingleJobInformation.results.category;
      this.subcategories = this.allSingleJobInformation.results.subcategories;
      this.availableFor = this.allSingleJobInformation.results.availableFor;
      this.isSalaryNegotiable =
        this.allSingleJobInformation.results.isSalaryNegotiable;
      (this.minimumSalary = {
        currency: this.allSingleJobInformation.results.minimumSalary.currency,
        salaryRange:
          this.allSingleJobInformation.results.minimumSalary.salaryRange,
        salary: this.allSingleJobInformation.results.minimumSalary.salary,
      }),
        (this.maximumSalary = {
          currency: this.allSingleJobInformation.results.maximumSalary.currency,
          salaryRange:
            this.allSingleJobInformation.results.maximumSalary.salaryRange,
          salary: this.allSingleJobInformation.results.maximumSalary.salary,
        }),
        (this.jobStartDate =
          this.allSingleJobInformation.results.jobStartDate != null
            ? moment(
                String(this.allSingleJobInformation.results.jobStartDate)
              ).format("YYYY-MM-D")
            : "");
      this.jobEndDate =
        this.allSingleJobInformation.results.jobStartDate != null
          ? moment(
              String(this.allSingleJobInformation.results.jobEndDate)
            ).format("YYYY-MM-D")
          : "";
      this.jobLocations = [];
      this.id = this.allSingleJobInformation.results._id;
      this.allSingleJobInformation.results.jobLocation.forEach((ele) => {
        this.jobLocations.push({ location: ele.location });
      });
    } else if (this.$route.params.company != undefined) {
      await this.singleJobInformationAdmin(this.$route.params.company);
      if (this.allSingleJobInformation.results != undefined) {
        this.jobPosition = this.allSingleJobInformation.results.jobPosition;
        this.subscriptionType =
          this.allSingleJobInformation.results.subscriptionType;
        this.numberOfVacancy =
          this.allSingleJobInformation.results.numberOfVacancy;
        this.jobLevel = this.allSingleJobInformation.results.jobLevel;
        this.category = this.allSingleJobInformation.results.category;
        this.subcategories = this.allSingleJobInformation.results.subcategories;
        this.availableFor = this.allSingleJobInformation.results.availableFor;
        this.isSalaryNegotiable =
          this.allSingleJobInformation.results.isSalaryNegotiable;
        (this.minimumSalary = {
          currency: this.allSingleJobInformation.results.minimumSalary.currency,
          salaryRange:
            this.allSingleJobInformation.results.minimumSalary.salaryRange,
          salary: this.allSingleJobInformation.results.minimumSalary.salary,
        }),
          (this.maximumSalary = {
            currency:
              this.allSingleJobInformation.results.maximumSalary.currency,
            salaryRange:
              this.allSingleJobInformation.results.maximumSalary.salaryRange,
            salary: this.allSingleJobInformation.results.maximumSalary.salary,
          }),
          (this.jobStartDate = moment(
            String(this.allSingleJobInformation.results.jobStartDate)
          ).format("YYYY-MM-D"));
        this.jobEndDate = moment(
          String(this.allSingleJobInformation.results.jobEndDate)
        ).format("YYYY-MM-D");
        this.jobLocations = [];
        this.id = this.allSingleJobInformation.results._id;
        this.allSingleJobInformation.results.jobLocation.forEach((ele) => {
          this.jobLocations.push({ location: ele.location });
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
